$(function () {
	var alignAddressHeight = function () {
		$('.addressholder').css('height', 'auto');
		topheight = 0;
		$('.addressholder').each(function () {
			tmpheight = $(this).height();
			if (tmpheight > topheight) {
				topheight = tmpheight
			}
		});
		$('.addressholder').height(topheight);
	}
	if ($(".addressholder").length) {
		alignAddressHeight();
		$(window).resize(function () {
			alignAddressHeight();
		});
	}


	if ($("#addressfilter input").length) {
		var addressinput = $("#addressfilter input");
		$(addressinput).on('keyup', function () {
			searchterm = $(addressinput).val().toLowerCase();
			$(".addresscontent").each(function () {
				contains = false;
				adresscontents = $(this).html().toLowerCase();
				adresscontents = strip(adresscontents);
				adresscontents = adresscontents.replace("/addressline/g", "");
				if (adresscontents.indexOf(searchterm) > -1) {
					contains = true;
				}
				if (contains) {
					$(this).parents('.addressholder').parent().addClass('inheader');
					$(this).parents('.addressholder').parent().removeClass('notheader');
				} else {
					$(this).parents('.addressholder').parent().removeClass('inheader');
					$(this).parents('.addressholder').parent().addClass('notheader');
				}

			});
			alignAddressHeight();
		});


		$(addressinput).trigger('keyup');
	};




	$('a[href^="mailto:"]').click(function () {
		var mailto = jQuery(this).attr('href');
		ga('send', 'event', 'contact', 'mailto', mailto);
	});


	var scrolled = $('.mini-cart .viewport').scrollTop();
	var cartHeight = $('.mini-cart .viewport').height();
	var cartItemHeight = 100;


	$(".readmorelink").on("click", function (e) {
		$(".readmorelink").slideUp(50);
		$(".readmorebox").slideDown(200);
		e.preventDefault(); return false;
	});




	$(".mini-cart .prev").on("click", function (e) {
		scrolled = $('.mini-cart .viewport').scrollTop()
		if (scrolled > 0) {
			scrolled = scrolled - cartItemHeight;
			$(".mini-cart .viewport").stop().animate({
				scrollTop: scrolled
			});
		}
		e.preventDefault(); return false;
	});

	$(".mini-cart .next").on("click", function (e) {
		scrolled = $('.mini-cart .viewport').scrollTop()

		if (scrolled < cartHeight + cartItemHeight) {
			scrolled = scrolled + cartItemHeight;
			$(".mini-cart .viewport").stop().animate({
				scrollTop: scrolled
			});
		}
		e.preventDefault(); return false;
	});

	//Show/hide mini cart
	// $('.cart-link').on('click', function(e) {
	// 	$('.mini-cart').toggle();
	// 	if($('.mini-cart').is(':visible')) {
	// 		cartItemHeight = $('.mini-cart .viewport li').first().outerHeight(true);
	// 	}
	// 	e.preventDefault(); return false;
	// });

	// $('.add-to-wishlist').on('mouseover mouseout', function(e) {
	// 	var plus = $(this).find('.fa-plus');
	// 	var minus = $(this).find('.fa-minus');
	// 	if( e.type == "mouseover" ) {
	// 		plus.addClass('spin');
	// 		minus.addClass('spin-reverse');
	// 	}else{
	// 		plus.removeClass('spin');
	// 		minus.removeClass('spin-reverse');
	// 	}
	// });

	$('.product .read-more').on('click', function (e) {
		var readMoreContent = $('.product .description .read-more-content');
		if (readMoreContent.is(':visible')) {
			$(this).text('Read more...');
		} else {
			$(this).text('Read less...');
		}
		readMoreContent.slideToggle('fast');
		e.preventDefault(); return false;
	});

	$(document).on('click', '.minus-product', function (e) {
		var input = $(this).siblings('input');
		if (parseInt(input.val()) > 0) {
			input.val(parseInt(input.val()) - 1);
		}
		input.trigger('change');
	});

	$(document).on('click', '.add-product', function (e) {
		var input = $(this).siblings('input');
		input.val(parseInt(input.val()) + 1);
		input.trigger('change');
	});

	//Mega menu tablets
	$('.nav.is-tablet > li > a').on('click', function (e) {
		$('.clicked').not($(this)).removeClass('clicked');
		if ($(this).hasClass('clicked')) {
			return true;
		} else {
			$(this).addClass('clicked');
			e.preventDefault(); return false;
		}
	});

	var reqdate;
	$('input[name=delivery_method_id]').each(function (i) {
		if ($(this).prop('checked')) {
			hasdate = $(this).data('date');
			if (hasdate == 1) {
				$("#dateholder").show();
				reqdate = moment($("#requested_delivery_date").val(), "DD/MM/YYYY");
			} else {
				$("#dateholder").hide();
				$("#requested_delivery_date").val('');
			}
		}
	});


	$('#requested_delivery_date').hide();
	$('#datetimepicker').show();
	$('#datetimepicker').datetimepicker({
		inline: true,
		date: reqdate,
		sideBySide: true,
		format: 'DD-MM-YYYY',
		minDate: moment().add(2, 'd').toDate(),
		daysOfWeekDisabled: [0, 6]
	});

	$("input[name=delivery_method_id]").on('change', function () {
		$('input[name=delivery_method_id]').each(function (i) {
			if ($(this).prop('checked')) {
				hasdate = $(this).data('date');
				if (hasdate == 1) {
					$("#dateholder").show();
				} else {
					$("#dateholder").hide();
					$("#requested_delivery_date").val('');
				}
			}
		});
	});
	$("#checkoutform").on('submit', function () {
		$('input[name=delivery_method_id]').each(function (i) {
			if ($(this).prop('checked')) {
				hasdate = $(this).data('date');
				if (hasdate == 0) {
					$("#requested_delivery_date").val('');
				} else {
					$("#requested_delivery_date").val($("#datetimepicker").data('DateTimePicker').date().format('DD/MM/YYYY'));
				}
			}
		});
	});


	$('.filter-sort-bar select[name=orderby]').on('change', function () {
		var sortby = $(this).val();
		var perpage = $('input[name=perpage]').val();
		var pageurl = $('input[name=pageurl]').val();
		if (window.location.href.indexOf('/category/') !== -1) {
			window.location.href = "/category/" + pageurl + '/' + perpage + '/' + sortby;
		} else {
			window.location.href = "/" + pageurl + '/' + perpage + '/' + sortby;
		}
	});

	$('#quickorderform #quickaddmore').on('click', function () {
		$("#quickorderform .hidden-quickorder").first().removeClass('hidden-quickorder');
		if ($("#quickorderform .hidden-quickorder").length < 1) {
			$('#quickaddmore').parent().parent().remove();
		}
	});

	$("#quickorderform .hidden-quickorder input").each(function () {
		if ($(this).val() != "") $(this).parents('tr.hidden-quickorder').removeClass('hidden-quickorder');
	});
	$(document).on('input', '.error input,.notfound input,.found input', function () {
		$(this).parent().removeClass('error notfound found');
	});
	$('#quickorderform td.productcode input').on('blur', function () {
		var inputbox = $(this);
		if (inputbox.val().length > 3) {
			var token = $('#quickorderform input[name=_token]').val();
			var request = $.ajax({
				url: "/quick-order/ajax",
				method: "POST",
				data: { code: inputbox.val(), _token: token },
				dataType: "json"
			});
			request.done(function (rsp) {
				if (rsp.found == true) {
					inputbox.parent().addClass('found');
					$('.message', inputbox.parent()).html('');
				} else {
					inputbox.parent().addClass('notfound');
					$('.message', inputbox.parent()).html('Product not found');
				}
			});

			request.fail(function (jqXHR, textStatus) {
				inputbox.parent().removeClass('fail');
			});
		}
	});

	function filterproducts() {
		//data-variant1
		variant1 = $('#vaa_c_1').val();
		//data-variant2
		variant2 = $('#vaa_c_2').val();
		$('.varrow,.mobileprodtable').each(function () {
			var filterfound = false;
			if ($('.varquantity,.dumbvarquantity', this).val() > 0) {
				$(this).removeClass('hide');
				return true;
			}
			if (variant1) {
				filterfound = true;
				if (variant1 == $(this).data('variant1')) {
					$(this).removeClass('hide');
				} else {
					$(this).addClass('hide');
					return true;
				}
			}
			if (variant2) {
				filterfound = true;
				if (variant2 == $(this).data('variant2')) {
					$(this).removeClass('hide');
				} else {
					$(this).addClass('hide');
					return true;
				}
			}
			if (!filterfound) {
				$(this).removeClass('hide');
			}
		});
	}

	function filtertableheadings() {
		$('.variant-table').each(function (index, element) {
			var rows = $(this).find('.varrow');
			var numRows = rows.length;
			var hidden = 0;

			rows.each(function () {
				if ($(this).hasClass('hide')) {
					hidden++;
				}
			});

			if (hidden === numRows) {
				$(this).find('thead tr').addClass('hide');
				$('p.no-variants').show();
			} else {
				$(this).find('thead tr').removeClass('hide');
				$('p.no-variants').hide();
			}
		});

	}

	var variant1;
	var variant2;
	$('#vaa_c_1,#vaa_c_2,.varquantity').on('change', filterproducts);
	$('#vaa_c_1,#vaa_c_2,.varquantity').on('change', filtertableheadings);


	$('.lightlink').magnificPopup(
		{
			type: 'image',
			mainClass: 'mfp-with-zoom', // this class is for CSS animation below

			zoom: {
				enabled: true, // By default it's false, so don't forget to enable it

				duration: 300, // duration of the effect, in milliseconds
				easing: 'ease-in-out', // CSS transition easing function

				// The "opener" function should return the element from which popup will be zoomed in
				// and to which popup will be scaled down
				// By defailt it looks for an image tag:
				opener: function (openerElement) {
					// openerElement is the element on which popup was initialized, in this case its <a> tag
					// you don't need to add "opener" option if this code matches your needs, it's defailt one.
					return openerElement.is('img') ? openerElement : openerElement.find('img');
				}
			},
			gallery: {
				enabled: true
			}
		});



	$('.lightlinkiframe').magnificPopup(
		{
			type: 'iframe'
		});





	function hightlight_address() {
		$('.addressholder').each(function () {
			if ($('input', this).prop('checked')) {
				$(this).addClass('high');
			} else {
				$(this).removeClass('high');
			}

		});
	}
	hightlight_address();
	$('.addressholder input').on('change', hightlight_address);

	$('.addressholder').on('click', function (e) {
		$(this).find('input').prop('checked', 'checked').trigger('change');
		$('textarea[name="specialinstructions"]').val($(this).attr('data-special-instructions'));
	});


	function generate_table_default(parent) {
		if (typeof parent !== 'undefined') {
			var holder = $(parent).find('#smtabledefault');
			var newholder = $(parent).find('#xstabledefault');
		} else {
			var holder = $('#smtabledefault');
			var newholder = $('#xstabledefault');
		}
		var table = $('table', holder);
		var header = $('thead', table);
		var headerelements = $('th', header);
		var body = $('tbody', table);
		var bodyrows = $('tr.prodrows', body);

		var table;
		var tr;
		var td;
		var th;

		var headarray = [];
		var rowarray = [];
		var rowelarray = [];
		var rowdataarray = [];
		var counter = 0;
		var innercounter = 0;

		counter = 0;
		headerelements.each(function () {
			headarray[counter] = $(this).html();
			counter++;
		});

		counter = 0;
		bodyrows.each(function () {
			rowelarray = [];
			innercounter = 0;
			$('td,th', this).each(function () {
				rowelarray[innercounter] = $(this).html();
				innercounter++;
			});
			rowarray[counter] = rowelarray;
			rowdataarray[counter] = $(this).data();
			counter++;
		});


		$.each(rowarray, function (rindex, row) {
			table = $('<table></table>').addClass('mobileprodtable');
			$.each(row, function (cindex, column) {
				tr = $('<tr></tr>');
				td = $('<td></td>').html(column);
				if (headarray[cindex] != undefined) {
					th = $('<th></th>').html(headarray[cindex]);
					tr.append(th);
				} else {
					td.attr('colspan', 2);
				}
				tr.append(td);
				table.append(tr);
			});
			$('input', table).each(function () {
				$(this).removeClass('varquantity');
				$(this).addClass('dumbvarquantity');
				$(this).data('name', $(this).attr('name'));
				$(this).addClass('dumb' + $(this).attr('name'));
				$(this).removeAttr('name');
			});
			table.data(rowdataarray[rindex]);
			newholder.append(table);
		});

		$(document).on('change', '.dumbvarquantity', function () {
			name = $(this).data('name');
			val = $(this).val();
			$('input[name=\'' + name + '\']').val(val);
		});
		$(document).on('change', '.varquantity', function () {
			name = $(this).attr('name');
			val = $(this).val();
			$('.dumb' + name).val(val);
		});

	}
	generate_table_default();

	function generate_table_customer(parent) {
		if (typeof parent !== 'undefined') {
			var holder = $(parent).find('#smtablecustomer');
			var newholder = $(parent).find('#xstablecustomer');
		} else {
			var holder = $('#smtablecustomer');
			var newholder = $('#xstablecustomer');
		}

		var table = $('table', holder);
		var header = $('thead', table);
		var headerelements = $('th', header);
		var body = $('tbody', table);
		var bodyrows = $('tr.prodrows', body);

		var table;
		var tr;
		var td;
		var th;

		var headarray = [];
		var rowarray = [];
		var rowelarray = [];
		var rowdataarray = [];
		var counter = 0;
		var innercounter = 0;

		counter = 0;
		headerelements.each(function () {
			headarray[counter] = $(this).html();
			counter++;
		});

		counter = 0;
		bodyrows.each(function () {
			rowelarray = [];
			innercounter = 0;
			$('td,th', this).each(function () {
				rowelarray[innercounter] = $(this).html();
				innercounter++;
			});
			rowarray[counter] = rowelarray;
			rowdataarray[counter] = $(this).data();
			counter++;
		});


		$.each(rowarray, function (rindex, row) {
			table = $('<table></table>').addClass('mobileprodtable');
			$.each(row, function (cindex, column) {
				tr = $('<tr></tr>');
				td = $('<td></td>').html(column);
				if (headarray[cindex] != undefined) {
					th = $('<th></th>').html(headarray[cindex]);
					tr.append(th);
				} else {
					td.attr('colspan', 2);
				}
				tr.append(td);
				table.append(tr);
			});
			$('input', table).each(function () {
				$(this).removeClass('varquantity');
				$(this).addClass('dumbvarquantity');
				$(this).data('name', $(this).attr('name'));
				$(this).addClass('dumb' + $(this).attr('name'));
				$(this).removeAttr('name');
			});
			table.data(rowdataarray[rindex]);
			newholder.append(table);
		});

		$(document).on('change', '.dumbvarquantity', function () {
			name = $(this).data('name');
			val = $(this).val();
			$('input[name=\'' + name + '\']').val(val);
		});
		$(document).on('change', '.varquantity', function () {
			name = $(this).attr('name');
			val = $(this).val();
			$('.dumb' + name).val(val);
		});

	}
	generate_table_customer();

	// $('.add-to-wishlist a').on('click',function(event){
	// 	event.preventDefault();
	// 	$(this).closest('form').submit();
	// });

	$("#orderbydd").on('change', function () {
		url = [location.protocol, '//', location.host, location.pathname, '?sort=', $(this).val()].join('');
		window.location.href = url;
	});

	//Form dropdowns select2
	$('.cms-content .gform_wrapper select').not('.cms-content .gform_wrapper .no-search select').select2();
	$('.cms-content .gform_wrapper .no-search select').select2({
		minimumResultsForSearch: Infinity
	});

	//Favourites search
	var pageFavs = $('.favbody .table-row');
	$('.fav-search-bar').on('keyup', function () {
		var s = $(this).val().toLowerCase();
		if (s == "") {
			$('.favbody .table-row').removeClass('hidden filtered');
			$(this).parent().find('.clear-search').removeClass('open');
		} else {
			$('.favbody .table-row').removeClass('hidden');
			$(this).parent().find('.clear-search').addClass('open');
			pageFavs.each(function (index) {
				var el = $(this);
				var prodTitle = el.find('.product-title');
				var variantTitle = el.find('.variant-title');
				var variantAttr = el.find('.variant-attribute');

				if ((variantTitle.length && variantTitle.text().toLowerCase().indexOf(s) === -1) &&
					(variantAttr.length && variantAttr.text().toLowerCase().indexOf(s) === -1)) {
					// el.removeClass('hidden');
					el.addClass('hidden');
				} else {
					el.addClass('filtered');
				}

				if (prodTitle.length && prodTitle.text().toLowerCase().indexOf(s) === -1) {
					// el.removeClass('hidden');
					el.addClass('hidden');
				} else {
					el.addClass('filtered');
				}

			});
		}
	});
	$('.search-favourites .clear-search').on('click', function (e) {
		$(this).siblings('input').val('').trigger('keyup');
		e.preventDefault(); return false;
	});

	$('.favourite-wrap .add-to-basket form, .favourite-wrap .fav-product-variants form').on('submit', function (e) {

		var el = $(this);

		el.append('<i class="fa fa-refresh fa-spin"></i>');

		var action = el.prop('action');
		var xhr = $.ajax({
			url: action,
			method: 'POST',
			data: el.serialize() + '&ajax=true'
		});

		xhr.done(function (data) {
			el.find('.fa-refresh').detach();
			el.parents('.fav-actions').after('<div class="alert alert-' + data.status + '">' + data.message + '</div>');
			$('.cart-wrap .basket-count').text(data.count);
			setTimeout(function () {
				el.parents('.fav-actions').siblings('.alert').fadeOut(function () { $(this).detach(); });
			}, 3000);
		});
		e.preventDefault(); return false;
	});

	//Load product variants
	var waypoints = $('.load-variants').waypoint({
		handler: function (direction) {
			if (direction === 'down') {
				loadVariants(this.element);
			}
		},
		offset: '100%'
	});

	var pi = 0;
	function loadVariants(elem) {
		var el = $(elem).find('form .product-variants');
		if (!el.hasClass('loaded')) {

			el.html('<i class="fa fa-refresh fa-spin"></i> Loading variants');
			el.parents('.fav-product-variants').siblings('.buttons').find('.add-to-basket').hide();

			var prod_url = el.parents('.fav-product-variants').data('url');
			var xhr = $.ajax({
				url: prod_url + '?variantsajax=1'
			});

			xhr.always(function (data) {
				var product_table = $('.product-table', data);
				if (product_table.length) {
					el.html(product_table.html());
					el.append('<div class="add-variants-to-basket"><button class="red-btn">Add to basket</button></div>');
					generate_table_customer(el);
				} else {
					el.empty();
					el.parents('.fav-product-variants').siblings('.buttons').find('.add-to-basket').show();
				}
				el.addClass('loaded');
			});

		}
	}
	$('.favourite-wrap .fav-product-variants form .product-variants').each(function (e) {

		return;
		// if(pi > 2) {
		//	Restrict for testing	
		// 	return;
		// }


		pi++;
	});

	//Marketing prefs
	$('.prefs-show-email').on('click', function (e) {
		if ($('.opt-in-form').is(':visible')) {
			$('.opt-in-form').slideUp('fast');
		}
		if (!$('.get-prefs-form').is(':visible')) {
			$('.get-prefs-form').slideDown('fast');
		} else {
			$('.opt-in-form').slideDown('fast');
			$('.get-prefs-form').slideUp('fast');
		}
		$('.get-non-web-prefs-form').slideUp('fast');
		e.preventDefault();
	});

	$('.prefs-show-non-web').on('click', function (e) {
		if ($('.opt-in-form').is(':visible')) {
			$('.opt-in-form').slideUp('fast');
		}
		$('.get-prefs-form').slideUp('fast');
		if (!$('.get-non-web-prefs-form').is(':visible')) {
			$('.get-non-web-prefs-form').slideDown('fast');
		} else {
			$('.opt-in-form').slideDown('fast');
			$('.get-non-web-prefs-form').slideUp('fast');
		}
		e.preventDefault();
	});

	//Accordions
	$('.cms-accordion').quickAccordion();

});

var getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = decodeURIComponent(window.location.search.substring(1)),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : sParameterName[1];
		}
	}
};

var strip = function (html) {
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

	// New JS as of Reskin July 2018
	; (function (window, document, $, undefined) {
		let ClickOrTouchEvent = ("ontouchend" in document.documentElement ? "touchend" : "click");
		$(function () {
			/*=============================| initial |================================*/
			var _document = $(document),
				_window = $(window),
				_html = $('html'),
				_body = $('body'),
				breakpoints = {
					xs: 480,
					sm: 768,
					md: 992,
					lg: 1200,
					xl: 1400,
					xxl: 1600
				};

			/**
			 * Gets the width of a browsers scroll bar
			 * @return {Number} Returns width of scrollbar
			 */
			function getScrollBarWidth() {
				var $outer = $('<div>').css({ visibility: 'hidden', width: 100, overflow: 'scroll' }).appendTo('body'),
					widthWithScroll = $('<div>').css({ width: '100%' }).appendTo($outer).outerWidth();
				$outer.remove();
				return 100 - widthWithScroll;
			}

			// Gets the width of the viewport plus the scroll bar
			/**
			 * Gets the width of the viewport plus the scroll bar
			 * @return {Number} Returns width of screen including scrollbar (if present)
			 */
			function getScreenWidth() {
				var appVW = _window.width(),
					appVH = _window.height(),
					scrollWidth = getScrollBarWidth(),
					bodyHeight = getBodyHeight();

				return (bodyHeight >= appVH) ? appVW + scrollWidth : appVW;
			}

			/**
			 * Gets the height of the viewport
			 * @return {Number} Returns height of viewport
			 */
			function getScreenHeight() {
				var appVH = _window.height();
				return appVH;
			}

			/**
			 * Gets the height of the body
			 * @return {Number} Returns height of the body
			 */
			function getBodyHeight() {
				var bodyHeight = $('body').height();
				return bodyHeight;
			}

			/**
			 * Checks if variable is empty
			 * @param  {mixed}  elem 	Variable to check
			 * @return {Boolean}      	Returns true if empty and false if not
			 */
			function isEmpty(elem) {
				return (
					elem === ''
					|| elem === null
					|| elem == undefined
					|| (elem.hasOwnProperty('length') && elem.length === 0)
					|| (typeof elem === 'object' && Object.keys(elem).length === 0)
					|| (typeof elem === 'number' && isNaN(elem))
				);
			}

			/**
			 * Initialisation function for your code. It will self invoke.
			 */
			(function init() {
				//Header Waypoint
				headerWaypointInit();

				//Event Tracking
				bindEventTracking();
			})();

			/*=============================| Header Waypoint |================================*/
			function headerWaypointInit() {
				headerWaypoint();
				_window.on('scroll.headerWaypoint', function () {
					headerWaypoint();
				});
				_window.on('resize.headerWaypoint', function () {
					headerWaypoint();
				});
			}

			var headerWaypointTimeout;
			function headerWaypoint() {
				clearTimeout(headerWaypointTimeout);
				var $topBar = $('#top-bar'),
					$header = $('#header');
				if (getScreenWidth() >= breakpoints.md) {
					var topBarHeight = $topBar.outerHeight(),
						$pageContent = $('section.page-content'),
						margin = 0;

					if (_window.scrollTop() >= topBarHeight) {
						_body.addClass('header-small');
						//$header.css('top', '');
					} else {
						_body.removeClass('header-small');
						//$header.css('top', topBarHeight + 'px');
					}

					headerWaypointTimeout = setTimeout(function () {
						var headerHeight = $header.outerHeight();

						if ($pageContent.length > 0) {
							if (!$pageContent.hasClass('header-small-no-margin')) {
								margin = (headerHeight + 20) + 'px';
							}
						}

						//$topBar.css('margin-bottom', margin);
					}, 300);
				} else {
					//$topBar.css('margin-bottom', '');
					//$header.css('top', '');
				}
			}

			//Responsive search placeholder
			$('input[name="s"]').each(function (e) {
				$(this).attr('data-ph', $(this).prop('placeholder'));
			});
			_window.on('resize', function (e) {
				if (_window.width() > 993 && _window.width() < 1200) {
					$('input[name="s"]').each(function (e) {
						$(this).prop('placeholder', 'Search');
					});
				} else {
					$('input[name="s"]').each(function (e) {
						$(this).prop('placeholder', $(this).attr('data-ph'));
					});
				}
			});
			_window.trigger('resize');

			/*=============================| Event Tracking |================================*/
			function bindEventTracking() {
				$('.products.considered .product-image a, .products.considered .product-title a').on('click.analyticsEvent', function (e) {
					var $this = $(this),
						$productBox = $this.closest('.product-box'),
						$title = ($this.parent().hasClass('product-title')) ? $this : $productBox.find('.product-title a'),
						label = $title.html().split(/\s+/).slice(0, 4).join(" ") + "...";

					if (typeof ga != 'undefined') {
						ga('send', 'event', 'Also Considered Products', 'Click', label);
					}
				});
			}

			/*========================================================================*\
			|-------------------------| Customer Journeys |----------------------------|
			\*========================================================================*/
			if ($('#customer-journey-popups')) {
				let customerJourneyRunningState = false;

				checkCustomerJourneyPopups();

				$('.modal').on('shown.bs.modal', function (e) {
					alignModalCenter($(this));
				});

				$(window).on('resize.customerJourneys', function (e) {
					alignModalCenter($('#customer-journey-popups').children('.modal:visible').first());
				});

				$('.modal').on('hidden.bs.modal', function (e) {
					updateCustomerJourneyPopup();
				});

				$('.modal .modal-footer button.close-all-popups').on(ClickOrTouchEvent + ".customerJourneys", function (e) {
					$('#customer-journey-popups').children('.modal').first().modal('hide');
					$('.modal-backdrop').fadeOut(300, function () {
						$(this).remove();
					});
					updateCustomerJourneyPopup('flush');
				});

				function alignModalCenter(modal) {
					let $modal = $(modal);

					if (!$modal) {
						return false;
					}

					let $modalDialog = $modal.find('.modal-dialog'),
						modalHeight = $modalDialog.outerHeight(),
						appVH = getScreenHeight(),
						mt = (appVH - modalHeight) / 2;

					$modalDialog.css({
						"margin-top": mt,
						"transition": "all 0.3s"
					});
				}

				function updateCustomerJourneyPopup(type) {
					if (isEmpty(type)) {
						type = "shift";
					}
					if (customerJourneyRunningState) {
						console.error("already running", type);
						return false;
					}
					customerJourneyRunningState = true;

					let $cj = $('#customer-journey-popups'),
						shiftUrl = $cj.attr('data-shift-url'),
						flushUrl = $cj.attr('data-flush-url'),
						token = $cj.attr('data-token'),
						data = new FormData();

					//Handle removing modals
					if (type === "flush") {
						$cj.html("");
					} else {
						$cj.children('.modal').first().remove();
					}

					data.append("_token", token);
					let url = type === "flush" ? flushUrl : shiftUrl;

					//Handle removing popups from session
					$.ajax({
						data: data,
						type: "POST",
						url: url,
						cache: false,
						contentType: false,
						processData: false,
						success: function (data) {
							customerJourneyRunningState = false;
						},
						error: function (xhr, status, error) {
							customerJourneyRunningState = false;
							console.error("Failed to " + type + " customer journey");
						}
					});

					checkCustomerJourneyPopups();
				}

				function checkCustomerJourneyPopups() {
					let $cj = $('#customer-journey-popups'),
						$modal = $cj.children('.modal').first();

					if ($modal) {
						$modal.modal();
					}
				}
			}
		});
	})(window, document, window.jQuery);