// Written by Steve Everett
// Generates a really simple and quick accordion from the given element.
// Styles will have to be controlled seperately and it relies on jQuery and having the following structure...
/*
<div class="accordion">
    <h3>Title</h3>
    <div>Content</div>

    <h3>Title</h3>
    <div>Content</div>

    etc etc
</div>
*/
$.fn.quickAccordion = function() {
        i=0;
        return this.each(function() {

            var el = this;
            var openFirst = $(el).data('accordion-open-first');
            $(el).addClass('accordion'+i);

            if(typeof openFirst === 'undefined' || openFirst === null) {
                openFirst = true;
            }

            $('.accordion'+i+' > div').hide();
            if( openFirst ) {
                $('.accordion'+i+' > div').first().show();
                $('.accordion'+i+' > h3').first().addClass('open');
            }
            $('.accordion'+i+' > h3').on('click', function(e) {
                $(this).toggleClass('open');
                $(this).next('div').slideToggle('fast');
            });
            i++;
        });

};